<template>
    <div class="index-container" style="margin-top: 1rem;min-height: 100vh">
        <a target="_blank" href="https://line.me/ti/p/~@uday888" v-for="{ icon }, i in posters" :key="i" >
            <img class="poster-size fadein" :src="icon" />
        </a>
      </div>
  </template>
  <script>
  
  export default {
    name: 'promotions',
    data() {
      return {
        posters: [
            // { icon: require('@/assets/posters/P1.jpeg') },
            // { icon: require('@/assets/posters/P2.jpeg') },
            // { icon: require('@/assets/posters/P3.jpeg') },
            // { icon: require('@/assets/posters/P4.jpeg') },
            { icon: 'https://cdn.discordapp.com/attachments/1037761392385720380/1038845311377997926/Cashback.jpg' },
            { icon: 'https://cdn.discordapp.com/attachments/1037761392385720380/1038845311772274808/e5f785a97026b368.jpg' },
            { icon: 'https://cdn.discordapp.com/attachments/1037761392385720380/1038845312179126272/Poster.jpg' },
            { icon: 'https://cdn.discordapp.com/attachments/1037761392385720380/1038845312632094841/15828f95603728ef.jpg' },
            { icon: 'https://cdn.discordapp.com/attachments/1037761392385720380/1038845313064120432/5f9ce099ed4b6868.jpg' },
            { icon: 'https://cdn.discordapp.com/attachments/1037761392385720380/1038845313378697237/Poster.jpg' }
        ]
      };
    },
    
    mounted() {
        // this.check_user();
    },
  }
  </script>
  
  <style lang="less" scoped>
    .index-container {
        padding: 10%;
    }
    .poster-size {
        border-radius: 15px;
        width: 100%;
        margin: 2%;
    }
    .index-container::-webkit-scrollbar {
      display: none
    }
  </style>
  